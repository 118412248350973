<template>
  <div>
    <div class="content w-full px-5 py-10 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#003E87"
      ></loading>
      <div class="" v-if="!isLoading">
        <div
          class="text-blue-500 font-bold text-2xl mb-1 text-center"
          v-if="userIsRegister"
        >
          ประวัติการส่งใบเสร็จ
        </div>
        <div v-if="userIsRegister">
          <div class="flex flex-col text-center my-5">
            <img
              :src="userLinePictureUrl"
              alt=""
              class="rounded-full h-24 w-24 mb-5 mx-auto"
            />

            <div class="text-2xl text-normal font-bold">
              {{ userLineDisplayName }}
            </div>
          </div>
        </div>
        <div class="flex mb-5" v-if="userIsRegister">
          <span
            class="bg-white text-black rounded-full py-1 px-6 mx-auto text-xl"
            @click="goTo('/redeem')"
          >
            ยอดซื้อสะสม
            <span class="font-bold text-blue-700">{{
              custInfo.CUS_POINT
            }}</span>
            บาท
          </span>
        </div>
        <div
          class="p-1 bg-gradient-to-b from-blue-700 via-blue-300 to-blue-700 rounded-2xl mb-5"
        >
          <div class="p-5 bg-white rounded-xl text-center">
            <span class="mt-5 text-xl font-bold text-blue-700"
              >Electric Vintage Enfa Car<br />รถไฟฟ้าสไดล์วินเทจเอนฟา
              <br /></span
            ><span class="font-bold text-lg text-gray-700"
              >มูลค่า 3,500 บาท
            </span>
            <img
              src="../assets/images/reward-2309eb.png"
              class="mx-auto my-8"
              style="width: 70%"
              alt=""
            />

            <div class="mt-8 grid gap-4 grid-cols-1">
              <button
                @click="goTo('/redeem')"
                class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
              >
                แลกของรางวัล
              </button>
            </div>
          </div>
        </div>
        <div
          class="overflow-x-auto rounded-lg overflow-y-auto relative"
          v-if="userIsRegister"
        >
          <table
            class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
          >
            <thead>
              <tr
                class="text-left text-sm bg-gradient-to-b from-blue-700 to-blue-800"
              >
                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  วันที่
                </th>
                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  เลขที่
                </th>
                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  จำนวนเงิน
                </th>

                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  สถานะ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(h, index) in custHistory" :key="index">
                <td
                  class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                >
                  {{ h.CB_SUBMIT_DATETIME }}
                </td>
                <td
                  class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                >
                  {{
                    h.CB_APPROVE_BILL_NO
                      ? h.CB_APPROVE_BILL_NO
                      : h.CB_SUBMIT_BILL_NO
                  }}
                </td>
                <td
                  class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                >
                  {{
                    h.CB_APPROVE_STATUS === "APPROVED"
                      ? h.CB_APPROVE_BUY_AMOUNT
                      : h.CB_SUBMIT_BUY_AMOUNT
                  }}
                </td>

                <td
                  class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                >
                  {{ h.CB_APPROVE_STATUS_TEXT }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import liff from "@line/liff";
import router from "../router";
import Service from "../service/service";
export default {
  name: "History",
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      isLoading: true,
      fullPage: true,
      custInfo: null,
      custHistory: null,
      countWait: 0,
      countApprove: 0,
      countReject: 0,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  methods: {
    goTo(r) {
      window.location.replace(r);
    },
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service
        .memberIsRegister(this.userLineId)
        .then(async (data) => {
          console.log(data);
          if (data.isRegisted === 1) {
            this.userIsRegister = true;
            this.custInfo = data.custInfo;
            await this.service
              .memberGetHistory(this.userLineId)
              .then((data) => {
                console.log(data);
                this.custHistory = data.historyData;
              });
          } else {
            this.$swal
              .fire({
                html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#b91c1c",
                confirmButtonText: "ไปลงทะเบียน  &rarr;",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  router.push("/register");
                }
              });
          }
          this.isLoading = false;
        });
    }
  },
};
</script>
