<template>
  <div>
    <div class="content w-full px-4 py-10 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <div class="">
        <img
          src="../assets/images/Aw_FB_BigC_Q4-Campaign_Electric-Vintage-Enfa-Car_V1_1200x1200px.jpg"
          class="w-full"
        />
      </div>
    </div>
  </div>
</template>
