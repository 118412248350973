<template>
  <div>
    <div class="content w-full px-5 py-10 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#003E87"
      ></loading>
      <div v-if="userIsRegister">
        <div class="lg:w-1/2 md:w-2/3 sm:w-full mx-auto" v-if="!isLoading">
          <div
            class="text-blue-700 font-bold text-2xl mb-3 text-center strokeme"
          >
            แลกของรางวัล
          </div>
          <!-- -->
          <div class="rounded-lg m-2 pb-2">
            <div class="flex flex-col">
              <div
                class="p-1 bg-gradient-to-b from-blue-700 via-blue-300 to-blue-700 rounded-2xl"
              >
                <div class="p-5 bg-white rounded-xl text-center">
                  <img
                    src="../assets/images/reward-2309eb.png"
                    class="mx-auto my-8"
                    style="width: 70%"
                    alt=""
                  />
                  <span class="mt-5 text-xl font-bold text-blue-700"
                    >Electric Vintage Enfa Car<br />รถไฟฟ้าสไดล์วินเทจเอนฟา
                    <br /></span
                  ><span class="font-bold text-lg text-gray-700"
                    >มูลค่า 3,500 บาท
                  </span>
                </div>
              </div>
              <div class="text-center px-5"></div>

              <div
                class="p-1 bg-gradient-to-b from-gray-300 via-gray-100 to-gray-300 rounded-2xl mt-5"
              >
                <div class="flex bg-white rounded-xl p-5">
                  <div class="flex w-2/3 text-left">
                    <label htmlFor="custom-input-number" class="p-3">
                      จำนวน
                    </label>
                    <div class="custom-number-input h-10 w-24">
                      <div
                        class="flex flex-row h-10 w-full rounded-lg relative bg-white mt-1"
                      >
                        <button
                          data-action="decrement"
                          class="bg-white text-gray-600 h-full w-20 rounded-l cursor-pointer outline-none border border-gray-500"
                          @click="redeemChange('-')"
                        >
                          <span class="m-auto text-2xl font-thin"> − </span>
                        </button>
                        <input
                          class="outline-none text-center focus:outline-none text-center text-2xl w-full bg-white font-bold text-md focus:text-black md:text-basecursor-default flex items-center text-pink-700 outline-none"
                          name="custom-input-number"
                          v-model="redeemCount"
                          v-mask="'#'"
                          type="tel"
                          readonly
                        />
                        <button
                          data-action="increment"
                          class="bg-white text-gray-600 h-full w-20 rounded-r cursor-pointer border border-gray-500"
                          @click="redeemChange('+')"
                        >
                          <span class="m-auto text-2xl font-thin"> + </span>
                        </button>
                      </div>
                    </div>
                    <label htmlFor="custom-input-number" class="p-3">
                      / 5
                    </label>
                    <span></span>
                  </div>
                  <div class="flex-row w-1/3 pr-2 pt-2 text-right">
                    คะแนนสะสมคงเหลือ<br /><span
                      class="font-bold text-pink-700 text-2xl"
                      >{{ custInfo.CUS_REMAIN_POINT_TEXT }}</span
                    >&nbsp; คะแนน
                  </div>
                </div>
              </div>
              <div class="text-pink-800 text-right my-5">
                *หมายเหตุ : 1 บัญชี สามารถแลกได้สูงสุด 5 รางวัล
              </div>

              <div class="mt-5">
                <label class="font-bold mb-2 text-blue-700 text-lg"
                  >กรุณากรอกข้อมูลที่อยู่ในการจัดส่ง</label
                >

                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">ชื่อ</label>
                  <input
                    v-model="inputFName"
                    type="text"
                    placeholder="ชื่อ"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">นามสกุล</label>
                  <input
                    v-model="inputLName"
                    type="text"
                    placeholder="นามสกุล"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">เบอร์โทรศัพท์</label>
                  <input
                    v-model="inputMobileNo"
                    v-mask="'###-###-####'"
                    type="tel"
                    placeholder="เบอร์มือถือ 10 หลัก"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">ที่อยู่ในการจัดส่ง</label>
                  <input
                    v-model="inputShipAddress"
                    type="text"
                    placeholder="ที่อยู่ในการจัดส่ง"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">ตำบล</label>
                  <input
                    v-model="inputShipTambon"
                    type="text"
                    placeholder="ตำบล"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">อำเภอ</label>
                  <input
                    v-model="inputShipAmphur"
                    type="text"
                    placeholder="อำเภอ"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">จังหวัด</label>
                  <select
                    v-model="inputShipProvince"
                    placeholder="กรุณาเลือกจังหวัด"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  >
                    <option value="">กรุณาเลือกจังหวัด</option>
                    <option
                      v-for="(s, index) in provinces"
                      :key="index"
                      :value="s.PRO_NAME"
                    >
                      {{ s.PRO_NAME }}
                    </option>
                  </select>
                </div>
                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                  <label class="font-bold mb-2">รหัสไปรษณีย์</label>
                  <input
                    v-model="inputShipZipcode"
                    type="tel"
                    placeholder="รหัสไปรษณีย์"
                    class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
                  />
                </div>
              </div>
              <div class="mt-8 grid gap-4 grid-cols-2">
                <button
                  @click="goRedeem()"
                  v-if="!btnDisable"
                  class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
                >
                  แลกของรางวัล
                </button>
                <button
                  @click="goTo('/history')"
                  class="w-full text-lg rounded-lg bg-gray-400 text-white py-2"
                >
                  ยกเลิก
                </button>
              </div>

              <div class="text-center mt-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import liff from "@line/liff";
import router from "../router";
import Service from "../service/service";
import configs from "../config";
export default {
  name: "Success",
  props: ["shop"],
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,
      isLoading: true,
      fullPage: true,
      submitBuyShop: this.shop,
      configs,
      countCoupon: 0,
      btnDisable: false,
      couponPrice: 0,
      provinces: null,
      inputShipProvince: "",
      redeemCount: 1,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },

  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.memberIsRegister(this.userLineId).then((data) => {
        // console.log(data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;

          this.userIsRegister = true;
          this.inputFName = this.custInfo.CUS_FNAME;
          this.inputLName = this.custInfo.CUS_LNAME;
          this.inputMobileNo = this.custInfo.CUS_MOBILE;
          this.inputShipAddress = this.custInfo.CUS_ADDRESS;
          this.inputShipTambon = this.custInfo.CUS_TAMBON;
          this.inputShipAmphur = this.custInfo.CUS_AMPHUR;
          this.inputShipProvince = this.custInfo.CUS_PROVINCE;
          this.inputShipZipcode = this.custInfo.CUS_ZIPCODE;
          if (data.shipInfo !== null) {
            this.inputFName = data.shipInfo.RH_SHIP_FNAME;
            this.inputLName = data.shipInfo.RH_SHIP_LNAME;
            this.inputMobileNo = data.shipInfo.RH_SHIP_MOBILE;
            this.inputShipAddress = data.shipInfo.RH_SHIP_ADDRESS;
            this.inputShipTambon = data.shipInfo.RH_SHIP_TAMBON;
            this.inputShipAmphur = data.shipInfo.RH_SHIP_AMPHUR;
            this.inputShipProvince = data.shipInfo.RH_SHIP_PROVINCE;
            this.inputShipZipcode = data.shipInfo.RH_SHIP_ZIPCODE;
          }
        } else {
          this.$swal
            .fire({
              html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#b91c1c",

              confirmButtonText: "ไปลงทะเบียน  &rarr;",
            })
            .then((result) => {
              if (result.isConfirmed) {
                router.push("/register");
              }
            });

          this.userIsRegister = false;
        }
      });
      await this.service.getProvinces().then((data) => {
        if (data.provinceData) {
          this.provinces = data.provinceData;
        }
      });

      this.isLoading = false;
    }
  },
  methods: {
    liffCloseWindow() {
      liff.closeWindow();
    },
    goTo(r) {
      this.$router.push(r);
    },
    redeemChange(type) {
      console.log(type);
      if (type === "+") {
        this.redeemCount += 1;
        if (this.redeemCount > 5) {
          this.redeemCount = 5;
        }
      }
      if (type === "-") {
        this.redeemCount -= 1;
        if (this.redeemCount < 1) {
          this.redeemCount = 1;
        }
      }
    },
    async goRedeem() {
      let isError = false;
      let txtError = "";
      if (this.custInfo.CUS_REMAIN_POINT < this.redeemCount * 10000) {
        isError = true;
        txtError += "<li>คะแนนของคุณไม่เพียงพอต่อการแลกของรางวัล</li>";
      } else {
        if (this.inputFName === "") {
          isError = true;
          txtError += "<li>กรุณากรอกชื่อ</li>";
        }
        if (this.inputLName === "") {
          isError = true;
          txtError += "<li>กรุณากรอกนามสกุล</li>";
        }
        if (this.inputMobileNo === "") {
          isError = true;
          txtError += "<li>กรุณากรอกเบอร์โทร</li > ";
        }

        if (this.inputShipAddress === "" || this.inputShipAddress === null) {
          isError = true;
          txtError += "<li>กรุณากรอกที่อยู่ในการจัดส่งของรางวัล</li>";
        }
        if (this.inputShipTambon === "" || this.inputShipTambon === null) {
          isError = true;
          txtError += "<li>กรุณากรอกตำบล/แขวง</li>";
        }
        if (this.inputShipAmphur === "" || this.inputShipAmphur === null) {
          isError = true;
          txtError += "<li>กรุณากรอกอำเภอ/เขต</li>";
        }
        if (this.inputShipProvince === "" || this.inputShipProvince === null) {
          isError = true;
          txtError += "<li>กรุณาเลือกจังหวัด</li>";
        }
        if (this.inputShipZipcode === "" || this.inputShipZipcode === null) {
          isError = true;
          txtError += "<li>กรุณากรอกรหัสไปรษณีย์</li>";
        }
      }
      if (!isError) {
        let formData = new FormData();
        formData.append("lineid", this.userLineId);
        formData.append("redeem_fname", this.inputFName);
        formData.append("redeem_lname", this.inputLName);
        formData.append("redeem_mobile", this.inputMobileNo);
        formData.append("redeem_address", this.inputShipAddress);
        formData.append("redeem_tambon", this.inputShipTambon);
        formData.append("redeem_amphur", this.inputShipAmphur);
        formData.append("redeem_province", this.inputShipProvince);
        formData.append("redeem_zipcode", this.inputShipZipcode);
        formData.append("redeem_count", this.redeemCount);
        formData.append("site", this.configs.prefix);
        formData.append(
          "action",
          this.configs.prefix.toLowerCase() + "/submit-redeem"
        );
        await axios.post(this.configs.urlBackendApi, formData).then((rez) => {
          console.log(rez);
          if (rez.data.data.isSuccess === 1) {
            this.$swal
              .fire({
                title: "แลกของรางวัลสำเร็จ",
                html:
                  "คุณแลกของรางวัล<br />รถไฟฟ้าสไตล์วินเทจเอนฟา<br />จำนวน " +
                  this.redeemCount +
                  " รางวัล",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#4FBE00",
                confirmButtonText: "ปิด",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  (this.step11 = true),
                    (this.step12 = false),
                    window.location.reload();
                }
              });
          } else {
            this.$swal({
              html: rez.data.data.msgError,
              title: "พบข้อผิดพลาด",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#b91c1c",
              confirmButtonText: "ตกลง",
            });
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#b91c1c",
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
<style scoped>
.card-con {
  flex: 1;
  filter: drop-shadow(0.5rem 0.5rem 0rem #f3f4f5);
}
@media screen and (max-width: 1000px) {
  .card {
    width: 100%;
  }
}
.card {
  min-height: 150px;
  margin: 2rem auto;
  border-radius: 0.5rem;
  -webkit-mask-image: radial-gradient(
      circle at right 50px bottom 14px,
      transparent 14px,
      red 14.5px
    ),
    radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
  -webkit-mask-size: 100%, 4px 12px;
  -webkit-mask-repeat: repeat, repeat-y;
  -webkit-mask-position: 0 14px, calc(100% - 48px);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
}
</style>
